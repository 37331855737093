
import { IonPage, IonGrid, IonRow, IonCol, IonInput } from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "../store";
import axios from "axios";

export default defineComponent({
  name: "ForgottenPassword",
  components: {
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
  },
  data: () => {
    return {
      store: useStore(),
      email: "",
      loading: false,
    };
  },
  methods: {
    async sendResetLink() {
      try {
        this.loading = true;
        // Call API
        const res = await axios.post(
          this.store.state.apiUrl + "/api/password-reset",
          { email: this.email }
        );
        if (res.data == 1) {
          // console.log("reset success");
          // console.log(res.data);
          alert(
            "Please check your emails for a password reset link. \n\nYou can close this page now."
          );
        } else {
          // console.log("reset fails");
          // console.log(res.data);
          alert("Something went wrong, please try again");
        }
        this.email = "";
        this.loading = false;
      } catch (error) {
        alert(error.response.data.message);
        console.log(error.response.data);
      }
    },
  },
});
